$table_cell_height: 42px;
$main_border_style: 1px solid black;

.domainTable {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    border: none;
  }

  thead {
    th {
      border-right: $main_border_style;
      &:last-child {
        border-right: 0;
      }
    }
    @media (max-width: 768px) {
      width: 145px;

      th {
        border: $main_border_style !important;
        min-height: $table_cell_height;
        border-bottom: 0;
      }
    }
  }
  tbody {
    tr {
      border-top: $main_border_style;
      border-collapse: collapse;
    }
    td {
      border-right: $main_border_style;
      border-collapse: collapse;
      padding: 5px;
      font-size: 15px;
      &:last-child {
        border-right: 0;
      }

      @media (max-width: 768px) {
        border-bottom: $main_border_style;
        min-height: $table_cell_height;
        min-width: 180px;
        &:last-child {
          border-right: $main_border_style;
        }
      }
    }
  }
  th {
    padding: 10px;
  }

  .tdIconSize {
    svg {
      font-size: 16px;
    }
  }
  .syncClass {
    svg {
      color: rgb(2, 107, 2);
    }
  }
  .scanIsonClass {
    color: #ffffff;
    background: rgb(2, 107, 2);
    border-radius: 4px;
    padding: 0 3px;
    margin-right: 5px;
  }
  .warnIsonClass {
    color: #ffffff;
    background: rgb(214, 164, 0);
    border-radius: 4px;
    padding: 0 3px;
  }
  .sslStatus {
    svg {
      color: red;
    }
  }
  .lastSync {
    color: rgb(62, 62, 192);
  }
}
