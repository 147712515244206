body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// policy page style
.createPolicyAction {
  width: 90vw;
  max-height: 90vh;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 4px;
  background: #f7f9fc;
  .closeModal {
    position: absolute;
    right: 20px;
    top: 20px;
    &:hover {
      svg {
        color: red;
      }
    }
  }
  .saveNewPolicy {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 30px 0;
    .savePolicyButton {
      display: flex;
      align-items: center;
      svg {
        margin-right: 15px;
      }
    }
  }

  .policyProfileList {
    width: 100%;
    .headingsBLock {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .inputs {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .policyProfile {
      position: relative;
      width: 100%;
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
        0px 1px 3px rgba(63, 63, 68, 0.15);
      background: #ffffff;
      border-radius: 4px;
      padding: 5px;
      .policySubHeading {
        position: absolute;
        top: 0;
        left: 15px;
        transform: translateY(-50%);
        background: #f7f9fc;
        padding: 0 5px;
      }
      .policyHeadings {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        .policyHeading {
          font-size: 16px;
          line-height: 18px;
          margin-top: 20px;
          margin-bottom: 30px;
          margin-right: 30px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          padding: 5px 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .enforcBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 50px;
        .actionsEnforc {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .buttonsGroupBlock {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            button {
              &:hover {
                background: #376fd0;
                color: #ffffff;
              }
            }
            .active {
              background: #88b4ff;
              color: #ffffff;
            }
          }
        }
      }
    }

    .privilegeTab {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      .removeAll,
      .enableJit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 5px;
        border-radius: 4px;
        max-width: 300px;
      }
      .jitPolicy {
        max-width: 300px;
        padding: 5px;
        border-radius: 4px;
        p {
          margin-top: 0;
        }
      }
      .switchStyle {
        .MuiSwitch-thumb {
          color: #376fd0;
        }
      }
    }

    .applicationControlSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      .appControll {
        max-width: 550px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        &:last-child {
          margin-bottom: 0;
        }
        .genStyleControll {
          height: 33px;
          padding: 5px;
        }
        .buttonsBlocks {
          padding: 0 5px;
          button {
            height: 100%;
            cursor: pointer;
            background: none;
            border: 0;
            border: 0;
            &:hover {
              background: #376fd0;
              color: #ffffff;
            }
          }
          .active {
            background: #88b4ff;
            color: #ffffff;
          }
        }
      }
    }

    .recordingSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      .recordingControll {
        max-width: 350px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      .genStyleRecording {
        height: 33px;
        padding: 5px;
      }
      .buttonsBlocks {
        padding: 0 5px;
        button {
          height: 100%;
          cursor: pointer;
          background: none;
          border: 0;
          border: 0;
          &:hover {
            background: #376fd0;
            color: #ffffff;
          }
        }
        .active {
          background: #88b4ff;
          color: #ffffff;
        }
      }
    }

    .applicationCatalogSection {
      width: 100%;
      padding: 20px;
    }
  }
}
