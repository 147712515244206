$base_color: #233044;

.firstStep {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    line-height: 15px;
    color: #3a3b3f;
    background: #eeedef;
    padding: 12px 8px;
    margin: 0;
    svg {
      cursor: pointer;
    }
  }
  .codeBlock {
    padding: 16px 12px;

    p {
      font-size: 16px;
      line-height: 18px;
      margin: 0;
      .blueText {
        color: #0027f2;
      }
      .redText {
        color: #bd0000;
      }
    }
  }
}

.MuiStepLabel-label {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.stepButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.secondStep {
  .styledForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    .formSides {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 15px;
      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #3a3b3f;
      }
      .twoInputsServer {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 15px;
      }
      .checkDomainConnection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .checkAnimationBlocks {
          width: 100%;
          margin-top: 15px;
        }
      }
      .test_conection_btn {
        width: 100%;
        cursor: pointer;
      }
      .sub_heading {
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 5px;
          height: 1px;
          width: 100%;
          background: #d8d8d8;
        }
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
}

.finaleShow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  .finaleSides {
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #3a3b3f;
    }
    p {
      font-size: 16px;
      line-height: 16px;
    }
  }
  .countsForDomain {
    display: flex;
    align-items: center;
    margin: 20px 0;
    div {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 16px;
      svg {
        color: #376fd0;
        margin-right: 8px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}
.resourceTab {
  max-width: 250px;
}

.endpointsResourceUserRow {
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.profileResourceGeneralTab {
  display: grid;
  grid-template-columns: 300px 1fr;
  column-gap: 10px;
  .userRow {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      transition: all 0.3s ease-in-out;
      background: #233044;

      th,
      td {
        color: #ffffff;
      }
    }

    .userRowName {
      max-width: 123px;
    }
  }

  .activeUserRow {
    background: #233044;

    th,
    td,
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.activeTenantRow {
  background: #233044;

  th,
  td {
    color: #ffffff;
  }
}

.tenantRow {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #233044;

    th,
    td {
      color: #ffffff;
    }
  }
  &:hover Button {
    color: #fff;
  }
}

.privilegeManagementTab {
  overflow: scroll;
}
// domain tab
.userRow {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #233044;
    color: white;

    th,
    td,
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }

  .userRowName {
    max-width: 123px;
  }
}

.activeUserRow {
  background: #233044;
  color: #ffffff;
  text-decoration: none;
  th,
  td,
  a {
    color: #ffffff;
    text-decoration: none;
  }
}

.endpointsTenantNameRow {
  max-width: 170px;
  padding: 20px;
}

.domainTab {
  display: grid;
  grid-template-columns: 240px 1fr;
  column-gap: 10px;

  // @media (min-width: 800px) {
  //   grid-template-columns: 1fr 3fr;
  // }

  .groupRowName {
    max-width: 250px;
    min-width: 190px;
  }

  .heading {
    width: 100%;
    display: flex;
    align-content: center;
    padding: 18px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .userHeading,
    .loginStatus,
    .userGroupHeading,
    .userGroupAuth,
    .userMembershipHeading {
      font-size: 17px;
      line-height: 18px;
      font-weight: 600;
    }
    .firsGroup,
    .secondGroup {
      display: flex;
      align-items: center;
    }
    .secondGroup {
      justify-content: space-between;
      width: 100%;
      .subGroup {
        display: flex;
        align-items: center;
      }
    }
    .loginStatus {
      padding-left: 18.5px;
    }
    .userGroupHeading {
      padding-left: 8rem;
    }
    .userGroupAuth {
      padding-left: 14.5rem;
    }
    .userMembershipHeading {
      margin-right: 20px;
    }
    .userHeading {
      padding-left: 20px;
      padding-right: 18.5px;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      width: 181px;
      text-align: center;
    }
  }

  .userName {
    font-size: 18px;
    max-width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: flex-start;
    cursor: pointer;
  }
  .tabsStyle {
    min-width: 85%;
    padding: 5px !important;
  }
  .userGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .actionBlock {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        white-space: nowrap;
      }
    }
    .groupName {
      width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      position: relative;
      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
      }
    }
    .userStatusInGroup {
      width: 175px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.125rem;
      font-weight: 500;
      position: relative;
      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .leftSide {
    width: 190px;
  }
}

.domainTabWithoutApps {
  grid-template-columns: 1fr 1fr;
}

.applicationTab {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-flow: row;
  column-gap: 10px;
  width: 100%;
  .MuiTableContainer-root {
    height: fit-content !important;
    max-height: 100vh !important;
    overflow-y: auto !important;
  }
}

// servers page style
.oneBlockTable {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  .Mui-selected {
    color: #8063d3 !important;
  }
  .tabsStyle {
    padding: 0;
    overflow-y: auto;
    .TabDomainName {
      padding: 15px 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }
  }
  .deviceTabs {
    height: auto;
  }
  .localSearchAddReload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 8px;
    .localSearch {
      width: 225px;
      height: 36px;
      position: relative;
      margin-right: 6px;
      .searchInput {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border: 1px solid #e4e7eb;
        box-sizing: border-box;
        border-radius: 2px;
        padding-left: 6px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #8b8d93;
      }
      .searchIcon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        color: #8b8d93;
      }
    }
    .reloadItems {
      background: none;
      cursor: pointer;
      border: 1px solid #e4e7eb;
      margin-right: 6px;
      svg {
        color: #8b8d93;
      }
    }
    .mainBtnStyle {
      height: 36px;
      border-radius: 2px;
    }
    .addItem {
      width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8063d3;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      border: none;
      cursor: pointer;
    }
  }
  .devicesList {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .deviceItemInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 24px;
      position: relative;
      cursor: pointer;
      &::after {
        display: flex;
        content: "";
        height: 1px;
        width: 80%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: #e4e7eb;
      }
      &:hover {
        background: #f9f8fb;
      }
      .info {
        p {
          margin: 0;
        }
        .heading {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #3a3b3f;
          margin-bottom: 5px;
        }
        .subHeading {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8b8d93;
        }
      }
      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          border: none;
          background: none;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #929499;
          }
          &:hover {
            background: #88898b;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .infoTabs {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    height: fit-content;

    .Mui-selected {
      background: #f9f8fb;
    }

    .agentInfoSection {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
  }
  .firstBlock {
    display: flex;
    align-items: flex-start;
    .headings {
      margin-right: 140px;
      p {
        margin: 0;
        padding-top: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3a3b3f;
      }
    }
    .informations {
      p {
        margin: 0;
        padding-top: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3a3b3f;
      }
    }
  }
  .secondBlock {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #3a3b3f;
    }
    .secondInfoBlock {
      display: flex;
      align-items: flex-start;
      .headings {
        margin-right: 140px;
        p {
          margin: 0;
          padding-top: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3a3b3f;
        }
      }
      .informations {
        .statusIcon {
          margin-right: 10px;
        }
        p {
          display: flex;
          align-items: center;
          margin: 0;
          padding-top: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3a3b3f;
          text-transform: capitalize;
        }
      }
    }
  }
  .usersTableSettings {
    padding: 15px;
    overflow-x: inherit;
    .userTableCell {
      font-size: 16px;
      padding: 10px 16px;
      .activeImage {
        margin-right: 10px;
      }
    }
    .buttonSell {
      position: relative;
      button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        &:hover {
          background: #a5a5a770;
          .userActionList {
            display: flex;
          }
        }
      }
    }
    .tableSearchSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 20px 0;
      .searchWrapper {
        display: flex;
        align-items: center;
        .searchBar {
          display: flex;
          align-items: center;
          width: 300px;
          position: relative;
          margin-right: 8px;
          .serachInput {
            width: 100%;
            height: 36px;
            padding: 5px;
            color: #8b8d93;
            font-size: 14px;
            line-height: 16px;
            background: #ffffff;
            border: 1px solid #e4e7eb;
            border-radius: 2px;
          }
          .searchIcon {
            position: absolute;
            right: 10px;
            top: 50%;
            color: #8b8d93;
            transform: translateY(-50%);
            // font-size: 15px;
          }
        }
        .spanText {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;

          color: #8063d3;
        }
      }
      .buttonsWrapper {
        display: flex;
        align-items: center;
        .mainButtons {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border: 1px solid #e4e7eb;
          border-radius: 2px;
          background: none;
          cursor: pointer;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
          .iconsColor {
            color: #8b8d93;
          }
        }
        .addPolicies {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          background: none;
          border-radius: 4px;
          height: 36px;
          min-width: 140px;
          margin-right: 10px;
        }
        .createPolicies {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          height: 36px;
          color: #ffffff;
          border-radius: 4px;
          min-width: 140px;
          margin-right: 10px;
        }
      }
    }
  }
  .groupList {
    display: flex;
    flex-direction: column;
    width: 100%;
    .itemsGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 65px;
      border-bottom: 1px solid #e4e7eb;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: #f9f8fb;
        .iconImg {
          color: #8063d3;
        }
        p {
          color: #8063d3;
        }
      }

      .actionGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .groupDeviceInfo {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .infoSection {
      width: 100%;
    }
    h2 {
      padding: 25px;
      margin: 0;
    }
    .firstBlock {
      position: relative;
      &:after {
        content: "";
        display: flex;
        width: 90%;
        height: 1px;
        background: #e4e7eb;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .secondBlock {
      margin-top: 0;
    }
    .firstBlock,
    .secondBlock {
      padding: 41px 24px;
    }
  }
  .noGroupeInfoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50% !important;
    text-align: center;
    .imgGroupe {
      position: relative;
      &::after {
        content: "";
        display: flex;
        position: absolute;
        background-size: cover;
        background-image: url("../../vendor/icons/ic_info.png");
        width: 40px;
        height: 40px;
        top: 45px;
        left: 5px;
        transform: translateX(-100%);
      }
    }
    .noGroupImg {
      margin-bottom: 60px;
    }
    .noGroupeText {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #3a3b3f;
      margin-top: 0;
    }
  }
  .repotsTablist {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    .reporsInfoBlock {
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgb(63 63 68 / 5%),
        0px 1px 3px rgb(63 63 68 / 15%);
      border-radius: 4px;
      padding: 5px;
    }
    .reportTdHead {
      font-size: 16px;
      line-height: 18px;
      color: #6e00ff;
    }
    .statusCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .freeze,
      .active,
      .disable {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        border-radius: 30px;
      }
      .active {
        background: green;
      }
      .freeze {
        background: yellow;
      }
      .disable {
        background: red;
      }
    }
  }
  .noClick {
    height: 50px;
  }
}
// .endpointsContainer {
//   display: grid;
//   grid-template-columns: 1fr 2fr;
//   column-gap: 15px;
//   max-width: 1300px;
// }
.endpointServersPages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  max-width: 1300px;
  .leftTabSide {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    padding: 20px 0;
    height: fit-content;
  }
  .leftTabSideActive {
    display: none;
  }
  .rightTabSide {
    height: 85vh;
    overflow-y: auto;
    .loading {
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 50px;
    }
    .textInRightSide {
      font-size: 20px;
      font-weight: 500;
      padding: 20px;
    }
  }
  .Mui-selected {
    color: #8063d3 !important;
  }
  .tabsStyle {
    padding: 0;
    overflow-y: auto;
    .TabDomainName {
      padding: 15px 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }
  }
  .deviceTabs {
    height: auto;
  }
  .localSearchAddReload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 8px;
    .localSearch {
      width: 225px;
      height: 36px;
      position: relative;
      margin-right: 6px;
      .searchInput {
        width: 100%;
        height: 100%;
        background: #ffffff;
        border: 1px solid #e4e7eb;
        box-sizing: border-box;
        border-radius: 2px;
        padding-left: 6px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #8b8d93;
      }
      .searchIcon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        color: #8b8d93;
      }
    }
    .reloadItems {
      background: none;
      cursor: pointer;
      border: 1px solid #e4e7eb;
      margin-right: 6px;
      svg {
        color: #8b8d93;
      }
    }
    .mainBtnStyle {
      height: 36px;
      border-radius: 2px;
    }
    .addItem {
      width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8063d3;
      font-family: "Roboto";
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      border: none;
      cursor: pointer;
    }
  }
  .devicesList {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .deviceItemInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 24px;
      position: relative;
      cursor: pointer;
      &::after {
        display: flex;
        content: "";
        height: 1px;
        width: 80%;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: #e4e7eb;
      }
      &:hover {
        background: #f9f8fb;
      }
      .info {
        p {
          margin: 0;
        }
        .heading {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #3a3b3f;
          margin-bottom: 5px;
        }
        .subHeading {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8b8d93;
        }
      }
      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          border: none;
          background: none;
          cursor: pointer;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #929499;
          }
          &:hover {
            background: #88898b;
            svg {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .infoTabs {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    height: fit-content;

    .Mui-selected {
      background: #f9f8fb;
    }

    .agentInfoSection {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }
  }
  .firstBlock {
    display: flex;
    align-items: flex-start;
    .headings {
      margin-right: 140px;
      p {
        margin: 0;
        padding-top: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3a3b3f;
      }
    }
    .informations {
      p {
        margin: 0;
        padding-top: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3a3b3f;
      }
    }
  }
  .secondBlock {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #3a3b3f;
    }
    .secondInfoBlock {
      display: flex;
      align-items: flex-start;
      .headings {
        margin-right: 140px;
        p {
          margin: 0;
          padding-top: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3a3b3f;
        }
      }
      .informations {
        .statusIcon {
          margin-right: 10px;
        }
        p {
          display: flex;
          align-items: center;
          margin: 0;
          padding-top: 40px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #3a3b3f;
          text-transform: capitalize;
        }
      }
    }
  }
  .usersTableSettings {
    padding: 15px;
    overflow-x: inherit;
    .userTableCell {
      font-size: 16px;
      padding: 10px 16px;
      .activeImage {
        margin-right: 10px;
      }
    }
    .buttonSell {
      position: relative;
      button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: none;
        border: none;
        cursor: pointer;
        position: relative;
        &:hover {
          background: #a5a5a770;
          .userActionList {
            display: flex;
          }
        }
      }
    }
    .tableSearchSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 20px 0;
      .searchWrapper {
        display: flex;
        align-items: center;
        .searchBar {
          display: flex;
          align-items: center;
          width: 300px;
          position: relative;
          margin-right: 8px;
          .serachInput {
            width: 100%;
            height: 36px;
            padding: 5px;
            color: #8b8d93;
            font-size: 14px;
            line-height: 16px;
            background: #ffffff;
            border: 1px solid #e4e7eb;
            border-radius: 2px;
          }
          .searchIcon {
            position: absolute;
            right: 10px;
            top: 50%;
            color: #8b8d93;
            transform: translateY(-50%);
            // font-size: 15px;
          }
        }
        .spanText {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;

          color: #8063d3;
        }
      }
      .buttonsWrapper {
        display: flex;
        align-items: center;
        .mainButtons {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border: 1px solid #e4e7eb;
          border-radius: 2px;
          background: none;
          cursor: pointer;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
          .iconsColor {
            color: #8b8d93;
          }
        }
        .addPolicies {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          background: none;
          border-radius: 4px;
          height: 36px;
          min-width: 140px;
          margin-right: 10px;
        }
        .createPolicies {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          height: 36px;
          color: #ffffff;
          border-radius: 4px;
          min-width: 140px;
          margin-right: 10px;
        }
      }
    }
  }
  .groupList {
    display: flex;
    flex-direction: column;
    width: 100%;
    .itemsGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 65px;
      border-bottom: 1px solid #e4e7eb;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background: #f9f8fb;
        .iconImg {
          color: #8063d3;
        }
        p {
          color: #8063d3;
        }
      }

      .actionGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  .groupDeviceInfo {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgba(63, 63, 68, 0.05),
      0px 1px 3px rgba(63, 63, 68, 0.15);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .infoSection {
      width: 100%;
    }
    h2 {
      padding: 25px;
      margin: 0;
    }
    .firstBlock {
      position: relative;
      &:after {
        content: "";
        display: flex;
        width: 90%;
        height: 1px;
        background: #e4e7eb;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .secondBlock {
      margin-top: 0;
    }
    .firstBlock,
    .secondBlock {
      padding: 41px 24px;
    }
  }
  .noGroupeInfoBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50% !important;
    text-align: center;
    .imgGroupe {
      position: relative;
      &::after {
        content: "";
        display: flex;
        position: absolute;
        background-size: cover;
        background-image: url("../../vendor/icons/ic_info.png");
        width: 40px;
        height: 40px;
        top: 45px;
        left: 5px;
        transform: translateX(-100%);
      }
    }
    .noGroupImg {
      margin-bottom: 60px;
    }
    .noGroupeText {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #3a3b3f;
      margin-top: 0;
    }
  }
  .repotsTablist {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
    .reporsInfoBlock {
      border: 1px solid rgba(63, 63, 68, 0.005);
      box-shadow: 0px 1px 0px rgb(63 63 68 / 5%),
        0px 1px 3px rgb(63 63 68 / 15%);
      border-radius: 4px;
      padding: 5px;
    }
    .reportTdHead {
      font-size: 16px;
      line-height: 18px;
      color: #6e00ff;
    }
    .statusCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .freeze,
      .active,
      .disable {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        border-radius: 30px;
      }
      .active {
        background: green;
      }
      .freeze {
        background: yellow;
      }
      .disable {
        background: red;
      }
    }
  }
  // tree view
  .treeViewList {
    .MuiTreeItem-label {
      font-size: 17px;
    }
    .parentNode {
      margin-bottom: 10px;
    }
    .computerName {
      .MuiTreeItem-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .noClick {
    height: 50px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    .leftTabSide {
      width: 100%;
      min-height: inherit !important;
      margin-bottom: 10px;

      .treeViewList {
        min-height: inherit;
        max-width: 100%;
      }
    }
  }
}
.endpointServersPagesActive {
  grid-template-columns: 0px 4fr;
}
// policy page style
.policySection {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  .addNewPolicyButtonSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .newPolicyButton {
      display: flex;
      align-items: center;

      svg {
        font-size: 24px;
        margin-right: 10px;
      }

      &:hover {
        box-shadow: 0px 1px 0px rgb(63 63 68 / 5%),
          0px 1px 3px rgb(63 63 68 / 15%);
      }
    }
  }
  .mainPolicyList {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
    border-radius: 4px;
    padding: 8px;
  }
}

// applications list page
.applicationsListPage {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  .filtersSection {
    display: grid;
    grid-template-columns: 4fr 4fr 1fr 1fr 1fr;
    align-items: center;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
    border-radius: 4px;
    padding: 15px 8px;
    #fileName {
      width: 250px;
    }
    .spacingBox {
      margin-right: 25px;
    }
    .applyFilters {
      height: 52px;
      font-size: 18px;
    }
  }
  .applicationList {
    background: #ffffff;
    border: 1px solid rgba(63, 63, 68, 0.005);
    box-shadow: 0px 1px 0px rgb(63 63 68 / 5%), 0px 1px 3px rgb(63 63 68 / 15%);
    border-radius: 4px;
    padding: 8px;
  }
}

.userModalInfo {
  .MuiPaper-root {
    min-width: 980px;
  }
  .userContent {
    h2 {
      text-align: left;
    }
    .info {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      .infoBlocks {
        width: 100%;
        display: flex;
        flex-direction: column;
        .heading {
          font-size: 1.8em;
          line-height: 20px;
          font-weight: 600;
        }
        .subHeading {
          font-size: 1.3em;
          line-height: 20px;
        }
      }
    }
  }
  .actionUserBlocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    .fullWidth {
      width: 100%;
    }
    .removeAll,
    .enableJit {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .switchStyle {
    .MuiSwitch-thumb {
      color: #376fd0;
    }
  }
}

.timers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 100%;
  padding: 8px;
  .time {
    font-size: 17px;
    line-height: 19px;
  }
  .text {
    font-size: 10px;
    margin-bottom: 1px;
  }
}

.treeItemActive {
  color: #e0e0eb;
  pointer-events: none;
}
.styled-text-area {
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
  max-width: 100%;
  min-width: 100%;
  max-height: 200px;
  min-height: 80px;
  font-size: 16px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
}

.privilegeLevelTableCell {
  width: 350px;
  & .privilegeLevelTableCell__dynamicItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .privilegeLevelTableCell__centerItem {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.profilePrivilegeLevelTableCell {
  display: flex;
  justify-content: center;
}

.organizationUnitContainer {
  display: grid;
  grid-template-columns: 250px 200px 1fr;
  column-gap: 10px;
}
.userMembershipContainer {
  display: grid;
  grid-template-columns: 250px 1fr;
  column-gap: 10px;
}
